/*-----------------------------------------------
|   Navigation bar
-----------------------------------------------*/
.navbar {
  font-weight: $font-weight-bold;
  font-size: $navbar-font-size;
  letter-spacing: 0.01em;
  border-bottom: $border-width solid rgba($white, 0.15);
  transition: $transition-base;
}
