/*-----------------------------------------------
|   Progress bar and circle
-----------------------------------------------*/
.progress-circle {
  height: 15.625rem;
  position: relative;
  font-family: $font-family-sans-serif;
  .progressbar-text{
    text-align: center;
    color: $gray-1100 !important;
    margin-bottom: 0;
    line-height: 1;
    span{
      font-size:  map_get($font-sizes, 0);
      display: block;
    }
    .value{
      font-weight: $font-weight-black;
      font-size: map_get($font-sizes, 5);
    }
    b{
      font-size:  map_get($font-sizes, 4);
      font-weight: $font-weight-black;
    }
  }
  svg {
    height: 100%;
    display: block;
  }
}

.progress-line{
  position: relative;
  font-family: $font-family-sans-serif;
  .progressbar-text{
    position: absolute;
    width: 100%;
    color: $gray-1100;
    left: 0;
    top: - map_get($spacers, 3);
    padding: 0;
    margin: 0;
    .value{
      right: 0;
      position: absolute;
    }
  }
}
